import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import InviteForm from '../components/Form'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import './headless-cms-sandbox.css'


class sandBoxPage extends React.Component {


  render() {
    const siteTitle = "Experience all the goodies of a Headless CMS in the WebriQ Sandbox"
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div className="headless-cms-sandbox pb-5">
        <section id="digital" className="component py-0">
            <Container>
              <Row className="align-items-center row justify-content-center">
                <Col md={6} className="ml-lg-auto">
                  <div className="content">
                    <Row className="align-items-start justify-content-start">
                    <Col xs="auto">
                      <LazyLoadImage className="img-fluid mb-0" effect="blur" src="/img/offer-1.png" alt="3"/>
                    </Col>
                    <Col xs={`${8} pl-0 pl-sm-3 pt-2`} lg={6} xl={7}>
                      <h5 className="font-weight-normal my-3">Deliver better Digital Experiences to your customers</h5>
                    </Col>
                  </Row>
                  </div>
                </Col>
                <Col md={5}>
                  <div className="text-center text-md-left">
                    <LazyLoadImage className="img-fluid" effect="blur" src="/img/digital.svg" alt="digital" width="207" height="341"/>
                  </div>
                </Col>
              </Row>
            </Container>
        </section>
        <section id="hub" className="component">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col md={5} className="text-center mb-4 mb-lg-0 order-2 order-md-1">
                <LazyLoadImage className="img-fluid" effect="blur" src="/img/hub.svg" alt="hub" width="266" height="341"/>
              </Col>
              <Col md={`${6} order-1 order-md-2 mb-4 mb-lg-0`}>
                <div className="content">
                  <Row className="align-items-start justify-content-start">
                    <Col xs="auto">
                      <LazyLoadImage className="img-fluid mb-0" effect="blur" src="/img/offer-2.png" alt="2"/>
                    </Col>
                    <Col xs={`${8} pl-0 pl-sm-3`} lg={6} xl={7}>
                      <h5 className="font-weight-normal my-3">Store, Edit, and Manage content in One Hub.</h5>
                    </Col>
                  </Row>

                </div>

              </Col>
            </Row>
          </Container>
        </section>
        <section id="value-content" className="component">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col md={6} xl={7}  className="ml-lg-auto mb-4 mb-lg-0">
                <div className="content">
                  
                  <Row className="align-items-center justify-content-start">
                    <Col xs="auto">
                      <LazyLoadImage className="img-fluid mb-0" effect="blur" src="/img/offer-3.png" alt="3"/>
                    </Col>
                    <Col xs={`${8} pl-0 pl-sm-3`} sm={6}>
                      <h5 className="font-weight-normal my-3">Value your content</h5>
                    </Col>
                  </Row>
                  <ul className="list-unstyled d-flex flex-column list-offer pl-3 pl-sm-5 ml-sm-5 justify-content-between">
                    <li>
                      <div className="checked-icon"><LazyLoadImage effect="blur" src="/img/checked-blue.png" alt="checked" width={35}/></div>
                      <span className="pl-3">DISTRIBUTE FREELY to all your digital assets</span>
                    </li>
                    <li>
                      <div className="checked-icon"><LazyLoadImage effect="blur" src="/img/checked-blue.png" alt="checked" width={35}/></div>
                      <span className="pl-3">CUSTOMIZED and STRUCTURED for optimal editing experiences</span>
                    </li>
                    <li>
                      <div className="checked-icon"><LazyLoadImage effect="blur" src="/img/checked-blue.png" alt="checked" width={35}/></div>
                      <span className="pl-3">ADAPT CONTINUOUSLY for new workflows</span>
                    </li>
                    <li>
                      <div className="checked-icon"><LazyLoadImage effect="blur" src="/img/checked-blue.png" alt="checked" width={35}/></div>
                      <span className="pl-3">IMPORT CONTENT from various, even legacy sources</span>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={5}>
                <div className="text-center text-md-left">
                  <LazyLoadImage className="img-fluid" effect="blur" src="/img/value.svg" alt="value" width="384" height="425"/>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        
        
        <section id="webriq-buttons" className="component pt-0">
          <Container>
              <div id="webriq-cta" className="text-center">
              <Row>
              <Col md={`${10} mx-auto`}>
                <h5 className="font-weight-normal text-white my-0 px-3">TIRED of cumbersome, non-flexible and difficult to use Content Management Systems (CMS) such as Wordpress or Drupal.</h5>
              </Col>
            </Row>
                  
              </div>
            
          </Container>
        </section>
        <section id="invite" className="component pt-4">
          <Container>
          <div className="content p-5">
          <Row>
            <Col lg={`${10} mx-auto`}>
              <h5 className="text-center mt-4">GET YOUR INVITE to OUR HEADLESS CONTENT MANAGEMENT <span className="text-blue">SANDBOX ACCOUNT</span> and just write away.</h5>
            <div id="crmWebToEntityForm">
              <meta httpEquiv="content-type" content="text/html;charset=UTF-8" />
              <InviteForm webriqform="false" action="https://crm.zoho.com/crm/WebToLeadForm" name="WebToLeads3371756000001229022" method="POST"  acceptCharset="UTF-8">
                <input type="text" style={{display: 'none'}} name="xnQsjsdp" defaultValue="b19f04dc9080dfd50a59b865053f951acaceb973cee42e9bd7e36fa287c7aedb" /> 
                <input type="hidden" name="zc_gad" id="zc_gad" defaultValue /> 
                <input type="text" style={{display: 'none'}} name="xmIwtLD" defaultValue="f1b44266ba4241dd782a5d4a2a574944fb68220bc18b2d6f76bfcbc57ea11d7e" /> 
                <input type="text" style={{display: 'none'}} name="actionType" defaultValue="TGVhZHM=" />
                <input type="text" style={{display: 'none'}} name="returnURL" defaultValue="https://glue.webriq.com" /><br />
                <Row>
                  <Col md={6}>
                    <div className="form-group">
                      <input className="form-control" type="text" maxLength={100} name="Company" id="InputCompany" required/>
                      <label htmlFor="InputCompany">Company<span className="text-danger">*</span></label>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <input className="form-control" type="text" maxLength={80} name="Last Name" id="InputFullname" required/>
                      <label htmlFor="InputFullname">First and Last Name<span className="text-danger">*</span></label>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <input className="form-control" type="email" maxLength={100} name="Email" id="InputEmail" required/>
                      <label htmlFor="InputEmail">Business Email<span className="text-danger">*</span></label>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <input className="form-control" type="text" maxLength={255} name="Website" id="InputWebsite" required/>
                      <label htmlFor="InputWebsite">Website<span className="text-danger">*</span></label>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="form-group">
                      <input className="form-control" type="number" maxLength={9} name="No of Employees" id="InputEmployees" required/>
                      <label htmlFor="InputEmployees">No. of Employees<span className="text-danger">*</span></label>
                    </div>
                  </Col>
                </Row>
                <div className="d-flex justify-content-start mt-3">
                  <button name="submit" id="formsubmit" type="submit" className="btn btn-primary hvr-shadow mx-0">Submit</button>  
                </div>
              </InviteForm>
            </div>
            </Col>
          </Row>
          
        </div>
          </Container>
        </section>
       
</div>
      </Layout>
    )
  }
}

export default sandBoxPage

export const sandBoxPageQuery = graphql`
  query sandBoxPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
